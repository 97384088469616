import React from 'react';
import { string } from 'prop-types';

import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { propTypes, LINE_ITEM_COUPON } from '../../util/types';

import css from './OrderBreakdown.module.css';

const LineItemCustomerCommissionCouponMaybe = props => {
  const { lineItems, isCustomer, marketplaceName, intl } = props;

  const coupon = lineItems.find(
    item => item.code === LINE_ITEM_COUPON && item?.discount
  );

  return isCustomer && coupon ? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage id="OrderBreakdown.coupon" values={{ marketplaceName }} />
      </span>
      <span className={css.itemValueNegative}>- {formatMoney(intl, coupon.lineTotal)}</span>
    </div>
  ) : null;
};

LineItemCustomerCommissionCouponMaybe.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  marketplaceName: string.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemCustomerCommissionCouponMaybe;
